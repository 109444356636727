import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    setTimeout(() => this.close(), 3000)
  }

  close() {
    this.element.classList.add("opacity-0")
    setTimeout(() => this.element.remove(), 500)
  }
}
