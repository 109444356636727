import { Controller } from "stimulus"

export default class extends Controller {
  static values = {  publishableKey: String, createCheckoutSessionUrl: String }

  async connect() {
    const stripe = await Stripe(this.publishableKeyValue);
    const response = await fetch(this.createCheckoutSessionUrlValue, {
      method: "POST",
      headers: { 
        accept: "application/json",
        'content-type': 'application/json',
        "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content")
      },
    });
    
    const { clientSecret } = await response.json();

    const checkout = await stripe.initEmbeddedCheckout({
      clientSecret,
    });

    checkout.mount(this.element);
  }
}
