import { Controller } from "stimulus"

export default class extends Controller {

  static targets = ['item', 'description']
  static classes = ['hidden']
  static values = { on: String, off: String }

  connect() {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'
    this.onValue = this.hasOnValue ? this.onValue : 'Hide'
    this.offValue = this.hasOffValue ? this.offValue : 'Show'
  }

  toggle() {
    this.itemTargets.forEach(item => {
      item.classList.toggle(this.class)
    })
    this.descriptionTargets.forEach(description => {
      description.innerHTML = description.innerHTML === this.onValue ? this.offValue : this.onValue
    })
  }

  show() {
    this.itemTargets.forEach(item => {
      item.classList.remove(this.class)
    })
    this.descriptionTargets.forEach(description => {
      description.innerHTML = this.offValue
    })
  }

  hide() {
    this.itemTargets.forEach(item => {
      item.classList.add(this.class)
    })
    this.descriptionTargets.forEach(description => {
      description.innerHTML = this.onValue
    })
  }
}
