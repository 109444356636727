import { Controller } from "stimulus";

export default class extends Controller {
  static values = { timeRemainingInSeconds: Number };

  initialize() {
    this.updateDisplay();
    this.startCountdown();
  }

  updateDisplay() {
    const minutes = Math.floor(this.timeRemainingInSecondsValue / 60);
    const seconds = this.timeRemainingInSecondsValue % 60;

    if (seconds < 0) {
      this.element.textContent = "00:00";
      return;
    }

    this.element.textContent = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  }

  startCountdown() {
    if (this.timer) {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      this.timeRemainingInSecondsValue--;

      if (this.timeRemainingInSecondsValue <= 0) {
        clearInterval(this.timer);
      }

      this.updateDisplay();
    }, 1000);
  }
}