import "stylesheets/application"

import { Application } from "stimulus"
import DropdownController from '../controllers/dropdown_controller'
import ToggleController from '../controllers/toggle_controller'
import FlashController from '../controllers/flash_controller'
import InstructionController from '../controllers/instruction_controller'
import TimezoneController from '../controllers/timezone_controller'
import AutosizeController from '../controllers/autosize_controller'
import DropdownMenuController from '../../components/become_ui/dropdown/component.js'
import TimerController from '../controllers/timer_controller'
import StripeController from '../controllers/stripe_controller'
import CountdownController from '../controllers/countdown_controller'

window.Stimulus = Application.start()
Stimulus.register("dropdown", DropdownController)
Stimulus.register("toggle", ToggleController)
Stimulus.register("flash", FlashController)
Stimulus.register("instruction", InstructionController)
Stimulus.register("timezone", TimezoneController)
Stimulus.register("autosize", AutosizeController)
Stimulus.register("dropdownmenu", DropdownMenuController)
Stimulus.register("timer", TimerController)
Stimulus.register("stripe", StripeController)
Stimulus.register("countdown", CountdownController)

import "@hotwired/turbo-rails"
Turbo.session.drive = false

require('tocbot');

import Rails from '@rails/ujs';
Rails.start();

import "chartkick/chart.js"
