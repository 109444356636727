import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggle', 'button']

  show() {
    setTimeout(() => {
      this.toggleTargets.forEach((target) => {
        target.classList.remove('hidden')
        target.classList.add('animate-fade-in-down')
      })
    }, 150);
  }

  hide() {
    setTimeout(() => {
      this.toggleTargets.forEach((target) => {
        target.classList.add('hidden')
      })
    }, 300);
  }

  toggle() {
    setTimeout(() => {
      this.toggleTargets.forEach((target) => {
        target.classList.toggle('hidden')
        target.classList.toggle('animate-fade-in-down')
      })
    }, 300);
  }
}
