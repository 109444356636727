import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { updateParam: String }

  connect() {
    fetch('/account/preferences',
      {
        method: 'PATCH',
        body: JSON.stringify({ user: { [this.updateParamValue]: true } }),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          "X-CSRF-Token": document.head.querySelector(`meta[name="csrf-token"]`).getAttribute("content")
        }
      }
    )
  }
}
